import React, { Fragment, useEffect, useRef } from 'react';
import { Root, Message } from './style';

import { useState } from '@hookstate/core';

import {
  currentRank,
  formatNumber,
  nextRank,
  nextRankTarget,
  useSiteData,
  YEAR_SECONDS,
} from 'utils';

import chaChing from 'assets/Claim/Page/Connected/cha-ching.mp3';

import { useWeb3 } from 'web3';
import vars from 'variables';

import LoadingOverlay from 'components/Common/LoadingOverlay';
import Modal from 'components/Common/Modal';
import Slider from './Slider';

const Connected = ({ onClaim }) => {
  const claimUpdate = useRef();
  const loadingOverlay = useRef();
  const modal = useRef();
  const { activate, bsc, wallet } = useWeb3();

  const percentageBNB = sessionStorage.getItem('percentageBNB');
  const percentageTOKEN = sessionStorage.getItem('percentageTOKEN');
  const bnbUsdPrice = useState(0);

  const state = useState({
    token: 'hodl',
    modalMessage: null,
    percentageBNB: percentageBNB === null ? 0 : Number(percentageBNB),
    percentageTOKEN: percentageTOKEN === null ? 100 : Number(percentageTOKEN),
    rewardTOKEN: 0,
    rewardBNB: 0,
  });

  const {
    claim: {
      claimAmountSubtitle,
      claimInfoText,
      yourAccountTitle,
      yourAccountDescription,
    },
  } = useSiteData().pages;

  const collectRewards = () => {
    if (wallet.balance === 0) {
      state.modalMessage.set('NOHODL');
      console.log('no hodl');
      modal.current.show();
      return;
    }

    const claimableBNB = wallet.claimableBNB;

    const gasLimit =
      state.percentageBNB.get() === 100 ? bsc.claimBNBLimit : bsc.reinvestLimit;

    const rewardTooLow = claimableBNB < gasLimit;

    if (rewardTooLow) {
      state.modalMessage.set('REWARDTOOLOW');
      modal.current.show();
      return;
    }

    var now = new Date().getTime() / 1000;
    if (now < wallet.nextClaimDateEpoch) {
      state.modalMessage.set('NOTREACHED');
      modal.current.show();
      return;
    }

    state.modalMessage.set(null);
    loadingOverlay.current.show('Your reward is being processed');

    wallet
      .collectRewards(state.percentageBNB.get())
      .then((data) => {
        if (onClaim) {
          activate().then(() => {
            loadingOverlay.current.hide();
            const audio = new Audio(chaChing);
            audio.play();
            onClaim({
              ...data,
              percentageBNB: state.percentageBNB.get(),
              percentageTOKEN: state.percentageTOKEN.get(),
              rewardTOKEN: state.rewardTOKEN.get(),
              rewardBNB: state.rewardBNB.get(),
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
        loadingOverlay.current.cancel();
        if (error.message.match('too early')) {
          state.modalMessage.set('NOTREACHED');
        } else {
          state.modalMessage.set('ERROR:' + error.message);
        }
        modal.current.show();
      });
  };

  const showStackingInfo = () => {
    state.modalMessage.set('STACKINGINFO');
    modal.current.show();
  };

  const startStacking = () => {
    if (wallet.balance < bsc.minTokensToStack) {
      state.modalMessage.set('NEEDMOREFORSTACKING');
      modal.current.show();
      return;
    }

    var now = new Date().getTime() / 1000;
    if (now < wallet.nextClaimDateEpoch) {
      state.modalMessage.set('NOTREACHED');
      modal.current.show();
      return;
    }

    loadingOverlay.current.show('Stacking is being activated');

    wallet
      .startStacking()
      .then(() => {
        activate().then(() => {
          loadingOverlay.current.hide();
        });
      })
      .catch((error) => {
        loadingOverlay.current.cancel();
        if (error.message.match('not reached')) {
          state.modalMessage.set('NOTREACHED');
        } else {
          state.modalMessage.set('ERROR:' + error.message);
        }
        modal.current.show();
      });
  };

  const stopStackingAndCollectRewards = (force) => {
    const claimableBNB = wallet.stackedBNB;

    const gasLimit =
      state.percentageBNB.get() === 100 ? bsc.claimBNBLimit : bsc.reinvestLimit;

    const rewardTooLow = claimableBNB < gasLimit;

    if (!force && rewardTooLow) {
      state.modalMessage.set('REWARDTOOLOW');
      modal.current.show();
      return;
    } else {
      modal.current.hide();
    }

    state.modalMessage.set(null);
    loadingOverlay.current.show('Your reward is being processed');

    wallet
      .stopStackingAndCollectRewards(state.percentageBNB.get())
      .then((data) => {
        if (onClaim) {
          activate().then(() => {
            loadingOverlay.current.hide();
            const audio = new Audio(chaChing);
            audio.play();
            onClaim({
              ...data,
              percentageBNB: state.percentageBNB.get(),
              percentageTOKEN: state.percentageTOKEN.get(),
              rewardTOKEN: state.rewardTOKEN.get(),
              rewardBNB: state.rewardBNB.get(),
            });
          });
        }
      })
      .catch((error) => {
        loadingOverlay.current.cancel();
        if (error.message.match('not reached')) {
          state.modalMessage.set('NOTREACHED');
        } else {
          state.modalMessage.set('ERROR:' + error.message);
        }
        modal.current.show();
      });
  };

  useEffect(() => {
    fetch(`${vars.apiHost}/-/prices`)
      .then((response) => response.json())
      .then((data) => {
        bnbUsdPrice.set(data.bnb);
      });
    // claimUpdate.current.show();
  }, []);

  const calculateEquity = () => {
    return ((wallet.balance * 100) / bsc.rewardPoolShare)
      .toFixed(4)
      .replace(/(?<=\.\d*[1-9])0+$|\.0*$/, '');
  };

  return (
    <Root>
      {wallet.stackingEnabled ? (
        <div className="header">
          <h4>
            <span>BNB coming your way!</span>
          </h4>
          <h1>{wallet.stackedBNB}</h1>
          <label className="dollarAmount">
            $
            {formatNumber(
              wallet.stackedBNB * (bsc.bnbUsdPrice || bnbUsdPrice.get()),
              2
            )}
          </label>
          <div className="status">
            <label>Stacking activated:</label>
            <p>{wallet.stackingSince}</p>
            <Slider state={state} />
            <div className="buttons">
              <a
                className="button blue"
                onClick={() => stopStackingAndCollectRewards()}
              >
                Stop stacking and collect
              </a>
            </div>
          </div>
          <div className="info">
            <span>
              <a onClick={showStackingInfo}>How does Stacking work?</a>
            </span>
          </div>
        </div>
      ) : (
        <div className="header">
          <h4>
            <span>{claimAmountSubtitle}</span>
          </h4>
          <h1>
            <span style={{ color: 'white', marginRight: 3 }}>$</span>
            {formatNumber(
              wallet.claimableBNB * (bsc.bnbUsdPrice || bnbUsdPrice.get()),
              2
            )}
          </h1>
          <label className="dollarAmount">
            {formatNumber(
              wallet.claimableBNB,
              wallet.claimableBNB > 0
                ? -Math.floor(Math.log10(wallet.claimableBNB) + 1) + 3
                : 0
            )}{' '}
            BNB
          </label>
          <div className="status">
            {wallet.nextClaimDate ? (
              <>
                <label>Due:</label>
                <p>{wallet.nextClaimDate}</p>
              </>
            ) : (
              <>
                <label></label>
                <p>No eligible claim</p>
              </>
            )}
            <Slider state={state} />
            <div className="buttons">
              <a className="button blue" onClick={collectRewards}>
                Claim now
              </a>
              <strong>or</strong>
              <a className="button blue" onClick={startStacking}>
                Stack
              </a>
            </div>
            <p className="forecast">
              <b>Annual Forecast: </b>
              {/* &nbsp;-&nbsp; */}
              {formatNumber(
                (YEAR_SECONDS / bsc.rewardClaimPeriod) * wallet.claimableBNB,
                2
              )}{' '}
              BNB / $
              {formatNumber(
                (YEAR_SECONDS / bsc.rewardClaimPeriod) *
                  wallet.claimableBNB *
                  bsc.bnbUsdPrice,
                2
              )}
              <div>
                <b>Reward Pool Cap: </b>
                {formatNumber(bsc.rewardPoolHardcap, 0)} BNB
              </div>
            </p>
          </div>
          <div className="info">
            <span>{claimInfoText}</span>
            <br />
            <span>
              <a onClick={showStackingInfo}>How does Reward Stacking work?</a>
            </span>
          </div>
        </div>
      )}
      <div className="body-width your-account">
        <div className="hr" />
        <h3>{yourAccountTitle}</h3>
        <p>{yourAccountDescription}</p>
        <ul>
          {wallet.stackingEnabled ? (
            <Fragment>
              <li></li>
              <li className="blue">
                <div>
                  <label>Your HODL Stacked</label>
                  <strong>{formatNumber(wallet.stackedAmount, 3)}</strong>
                </div>
              </li>
              <li></li>
            </Fragment>
          ) : (
            ''
          )}
          <li className="blue">
            <div>
              <label>Your Balance</label>
              <strong>{formatNumber(wallet.balance)} $HODL</strong>
              <strong>
                $
                {formatNumber(
                  (wallet.balance + wallet.stackedAmount) * bsc.hodlUsdPrice,
                  2
                )}
              </strong>
            </div>
          </li>
          {/* <li className="blue">
            <div>
              <label>Your total HODL in $</label>
              <strong>
                $
                {formatNumber(
                  (wallet.balance + wallet.stackedAmount) * bsc.hodlUsdPrice,
                  2
                )}
              </strong>
            </div>
          </li> */}
          <li>
            <div>
              <label>Tokens claiming BNB</label>
              <strong>{formatNumber(bsc.rewardPoolShare, 0)}</strong>
            </div>
          </li>
          <li>
            <div>
              <label>Your % of Reward Pool</label>
              <strong>{calculateEquity()}%</strong>
            </div>
          </li>
          <li>
            <div>
              <label>BNB collected to date</label>
              <strong>
                {formatNumber(wallet.claimedBNB, 3)}
                BNB / ${formatNumber(wallet.claimedBNB * bsc.bnbUsdPrice, 2)}
              </strong>
            </div>
          </li>
          <li className="rank">
            <div>
              <img src={currentRank(wallet.balance).image} />
              <div>You are a {currentRank(wallet.balance).name}</div>
              {nextRank(wallet.balance) ? (
                <small>
                  Reach&nbsp;
                  {nextRankTarget(wallet.balance)}
                  &nbsp;tokens to become a&nbsp;
                  {nextRank(wallet.balance).name}
                </small>
              ) : (
                <small>You are invincible! You reached the top!</small>
              )}
            </div>
          </li>
          <li>
            <div>
              <label>$HODL re-invested to date</label>
              <strong>{formatNumber(wallet.reinvested)}</strong>
            </div>
          </li>
        </ul>
        <a
          className="button blue logo"
          href={vars.links.buy}
          target="_blank"
          rel="noreferrer"
        >
          Buy $HODL now
        </a>
      </div>
      <LoadingOverlay usedRef={loadingOverlay} />
      <Modal usedRef={claimUpdate}>
        <Message>
          <h4>
            <span>NEW UPDATES!</span>
          </h4>
          <h2>MORE OPTIONS AND BONUSES!</h2>
          <p className="light">
            Reinvest into $HODL or $HODLX - simply click the dropdown on the
            claim page.
          </p>
          <p className="light">
            Reinvesting into $HODL is tax-free (normally 5%) with a{' '}
            {bsc.reinvestBonusses || {}}% bonus in $HODL!
          </p>
          <p className="light">
            🔥 PLUS - Reinvest 100% into $HODL and your next claim will be
            <strong> 1 day earlier</strong> 🔥
          </p>
        </Message>
      </Modal>
      <Modal usedRef={modal}>
        {state.modalMessage.get() === 'REWARDTOOLOW' ? (
          <Message>
            <h4>
              <span>We're sorry</span>
            </h4>
            <h2>Your claim is less than the gas fee</h2>
            {wallet.stackingEnabled ? (
              <p className="light">
                To prevent you losing money, please stack for a longer period of
                time! 🙏
                <a
                  className="button blue proceed"
                  onClick={() => stopStackingAndCollectRewards(true)}
                >
                  Proceed anyway
                </a>
              </p>
            ) : (
              <p className="light">
                To prevent you losing money,{' '}
                <a href={vars.links.buy}>
                  <span>buy more HODL</span>
                </a>{' '}
                so your rewards are above the gas fee or alternatively you can{' '}
                <a href={vars.links.stakehodl}>
                  <span>stake your HODL</span>
                </a>{' '}
                to earn HODLX!
              </p>
            )}
            <p className="light">
              Join us on{' '}
              <a href={vars.links.telegram}>
                <span>Telegram</span>
              </a>{' '}
              to learn more
            </p>
          </Message>
        ) : (
          ''
        )}
        {state.modalMessage.get() === 'NOTREACHED' ? (
          <Message>
            <h4>
              <span>Come back soon</span>
            </h4>
            <h2>You're a little early!</h2>
            <label>Next Collection:</label>
            <p>{wallet.nextClaimDate}</p>
            <a
              className="button blue logo"
              href="/stacking"
              target="_blank"
              rel="noreferrer"
            >
              Buy HODL now
            </a>
          </Message>
        ) : (
          ''
        )}
        {state.modalMessage.get() === 'STACKINGINFO' ? (
          <Message>
            <h4>
              <span>More BNB. Less fees.</span>
            </h4>
            <h2>How does stacking work?</h2>
            <p className="light">
              Every time you claim a reward you pay a gas fee for the
              transaction to occur. This fee is between ~$0.80 to ~$2, depending
              on whether you claim BNB, $HODL or $HODLX. By stacking your
              rewards, you can build up your claim amount to ensure it exceeds
              gas fees!
            </p>
            <p className="light">
              Please note - the maximum you can stack is{' '}
              <strong>{wallet.stackingLimit} BNB</strong>.
            </p>
            <a
              className="button blue logo"
              href="/stacking"
              target="_blank"
              rel="noreferrer"
            >
              More about stacking
            </a>
          </Message>
        ) : (
          ''
        )}
        {state.modalMessage.get() === 'NEEDMOREFORSTACKING' ? (
          <Message>
            <h4>
              <span>We're sorry</span>
            </h4>
            <h2>You need to stack more tokens</h2>
            <p className="light">
              The minimum you can stack is <strong>15,000,000 HODL</strong>{' '}
              Tokens. If you are below this then you can buy more.
            </p>
            <a
              className="button blue logo"
              href={vars.links.buy}
              target="_blank"
              rel="noreferrer"
            >
              Buy HODL now
            </a>
          </Message>
        ) : (
          ''
        )}
        {state.modalMessage.get() === 'NOHODL' ? (
          <Message>
            <h4>
              <span>No $HODL found</span>
            </h4>
            <h2>You have no $HODL in your wallet</h2>
            <a
              className="button blue logo"
              href={vars.links.buy}
              target="_blank"
              rel="noreferrer"
            >
              Buy HODL now
            </a>
          </Message>
        ) : (
          ''
        )}
        {(state.modalMessage.get() || '').startsWith('ERROR:') ? (
          <Message>
            <h4>
              <span>We're sorry</span>
            </h4>
            <h2>Error</h2>
            <p className="light">
              {state.modalMessage.get().replace('ERROR:', '')}
            </p>
          </Message>
        ) : (
          ''
        )}
      </Modal>
    </Root>
  );
};

export default Connected;
